<template>
    <Drawer v-model:visible="visible" :blockScroll="true" :header="$t('filter')" position="right" class="!w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
        <div class="card !p-2 flex flex-col flex-wrap">
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('leads.sex') }}</span>
                <SelectButton v-model="filters.sex" multiple :options="genders" optionLabel="icon" optionValue="value" dataKey="value" aria-labelledby="custom" class="justify-center w-full md:justify-start md:w-auto">
                    <template #option="slotProps">
                        <span :class="slotProps.option?.class">{{ slotProps.option?.icon }}</span>
                    </template>
                </SelectButton>
            </div>

            <div class="flex flex-col gap-1 mb-6">
                <ToggleButton 
                    v-model="filters.ready_to_move"
                    onIcon="pi pi-check" 
                    offIcon="pi pi-times" 
                    onLabel="Только готовые к переезду" 
                    offLabel="Готовые и не готовые к переезду" 
                    class="w-full"
                />
            </div>

            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('leads.recruiter') }}</span>
                <MultiSelect 
                    v-model="filters.responsibles" 
                    class="!w-[30rem] md:!w-[28rem] lg:!w-[33rem] xl:!w-[30rem]"
                    display="chip" 
                    :options="responsibles" 
                    optionValue="id" 
                    optionLabel="full_name" 
                    :placeholder="$t('Any')"
                    >
                    <template #option="slotProps">
                        {{ slotProps.option?.full_name }}
                    </template>
                </MultiSelect>
            </div>
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('leads.lang_lvl') }}</span>
                <MultiSelect v-model="filters.lang_lvl" :options="langLvls" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        {{ slotProps.option?.name }}
                    </template>
                </MultiSelect>
            </div>

            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('leads.city') }}</span>
                <AutoComplete  
                    v-model="selectedCity" 
                    :suggestions="cities" 
                    optionLabel="full_name" 
                    optionValue="id" 
                    forceSelection
                    :loading="citySelectLoading"
                    @complete="fetchCities"  
                    :placeholder="$t('City')"
                    fluid
                />
            </div>

            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('leads.vacancy') }}</span>
                <AutoComplete  
                    v-model="selectedVacancy" 
                    :suggestions="vacancies" 
                    optionLabel="title" 
                    optionValue="id" 
                    forceSelection
                    :loading="vacancySelectLoading"
                    @complete="fetchVacancies"  
                    :placeholder="$t('Vacancy')"
                    fluid
                />
            </div>

            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('leads.source') }}</span>
                <MultiSelect v-model="filters.source" :options="sources" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        {{ slotProps.option?.name }}
                    </template>
                </MultiSelect>
            </div>
        </div>
        
        <template #footer>
            <div class="flex items-center gap-2">
                <Button :label="$t('apply')" class="flex-auto" @click.stop="onFilter"></Button>
                <Button :label="$t('clear')" class="flex-auto" outlined @click.stop="clearFilter"></Button>
            </div>
        </template>
    </Drawer>
</template>

<script>
import { ref } from "vue";
import { usePermissions } from '../Composables/usePermissions';

export default {
    components: {},
    props: {
        active: {
            type: Boolean,
            required: true
        },
        filterData: {
            type: Object,
            required: false,
            default: {}
        },
        responsibles: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const sources = [
            { name: 'Личный поиск', id: 1 },
            { name: 'Facebook', id: 2 },
            { name: 'Сайт', id: 3 },
        ];

        const genders = [
            {
                icon: 'male',
                class: 'material-symbols-outlined text-blue-600',
                value: 1
            },
            {
                icon: 'female',
                class: 'material-symbols-outlined text-pink-600',
                value: 2
            },
            {
                icon: 'family_restroom',
                class: 'material-symbols-outlined text-indigo-600',
                value: 3
            }
        ];

        return { hasRole, hasPermission, genders, sources };
    },
    data() {
        return {
            filters: null,
            selectedResponsibles: null,
            langLvls: [{ name: 'A1', id: 1 }, { name: 'A2', id: 2 }, { name: 'B1', id: 3 }, { name: 'B2', id: 4 }],
            citySelectLoading: false,
            selectedCity: null,
            cities: [],
            vacancySelectLoading: false,
            selectedVacancy: null,
            vacancies: [],
        };
    },
    created() {
        this.filters = this.queryToFilter(this.filterData);
    },
    methods: {
        onFilter() {
            const filter = this.filterToQuery(this.filters);
            this.$emit('filter', filter);
            this.visible = false;
        },
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        clearFilter() {
            this.$emit('filter', null);
        },
        filterToQuery(filters) {
            filters = _.cloneDeep(filters);
            return filters;
        },
        queryToFilter(filters) {
            filters = _.cloneDeep(filters);
            if (filters) {
                for (let [key, value] of Object.entries(filters)) {
                    filters[key] = this.convertFilterValue(value);
                }
            }
            return filters;
        },
        convertFilterValue(value) {
            //Для множественных значений простого фильтра
            if (Array.isArray(value)) {
                let result = [];
                value.forEach((single) => {
                    single = this.convertFilterValue(single);
                    result.push(single);
                });
                return result;
            }

            if (value == 'true')
                return true;
            if (value == 'false')
                return false;

            if (typeof value == 'string') {
                value = value.replace('<=', '');
                value = value.replace('>=', '');
            }

            const parsedNumber = parseFloat(value);
            if (!isNaN(parsedNumber))
                return parsedNumber;
            return value;
        },
        fetchCities(event) {
            this.citySelectLoading = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    selected: this.filters.city,
                    attribute: 'full_name',
                    related_model: 'App\\Models\\City',
                    resource: 'App\\Http\\Resources\\CityResource',
                })
                .then(response => {
                    this.cities = response.data.data;
                    this.citySelectLoading = false;
                });
        },
        fetchVacancies(event) {
            this.vacancySelectLoading = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    selected: this.filters.vacancy,
                    attribute: 'title',
                    related_model: 'App\\Models\\Vacancy',
                    resource: 'App\\Http\\Resources\\VacancyResource',
                })
                .then(response => {
                    this.vacancies = response.data.data;
                    this.vacancySelectLoading = false;
                });
        },
    },
    computed: {
        visible: {
            get() {
                return this.active;
            },
            set(val) {
                this.$emit('update:active', val);
            }
        },
    },
    watch: {
        selectedCity(city) {
            if (city && city.hasOwnProperty('id')) {
                this.filters.city = city.id;
            }
            else {
                this.filters.city = null;
            }
        },
        selectedVacancy(vacancy) {
            if (vacancy && vacancy.hasOwnProperty('id')) {
                this.filters.vacancy = vacancy.id;
            }
            else {
                this.filters.vacancy = null;
            }
        },
        'filters.ready_to_move': function (val) {
            if (val === false) {
                this.filters.ready_to_move = null;
            }
        }
    }
}
</script>