<template>
    <div class="card flex flex-col !px-0 md:!px-6 !py-2">
        <ScrollPanel ref="commentsPanel" class="mt-2 w-100" :class="class">
            
            <template v-if="comments?.length" v-for="item in comments">
                <div v-if="item?.author_id == user.id">
                    <div class="flex flex-row-reverse my-4">
                        
                            <div class="inline-block max-w-xl font-normal border border-surface-200 dark:border-surface-700 bg-primary-100 text-primary-900 p-4 whitespace-normal rounded-lg shadow-md me-4">
                                <div class="flex flex-row-reverse font-normal justify-between items-end mb-2">
                                    <!-- <i class="pi pi-check text-green-400 ms-2"></i> -->
                                    <div><small>{{ item.created_at }}</small></div>
                                    <div class="text-surface-900 dark:text-surface-0 font-semibold me-4">{{ item?.author?.full_name }}</div>
                                </div>
                                <div>{{ item.comment }}</div>
                            </div>
                        
                    </div>
                </div>
                <div v-else>
                    <div class="flex flex-row my-4" v-element-visibility="(isVisible) => onCommentVisible(isVisible, item)">
                        <div class="basis-1/12 content-center justify-start me-2">
                            <Avatar :label="item?.author?.avatar ? null : item?.author?.lastname[0]?.toUpperCase()" :image="item?.author?.avatar" shape="circle" size="large" class="shadow-lg"/>
                        </div>
                        <div class="basis-11/12">
                            <div class="inline-block max-w-xl text-surface-700 dark:text-surface-100 font-normal border border-surface-200 dark:border-surface-700 p-4 whitespace-normal rounded-lg shadow-md">
                                <div class="flex flex-row font-normal justify-between items-start mb-2">
                                    <div class="text-surface-900 dark:text-surface-0 font-semibold me-4">{{ item?.author?.full_name }}</div>
                                    <div>
                                        <small>{{ item.created_at }}</small>
                                        <i v-if="item.read" class="pi pi-eye text-green-400 ms-2 !text-xs"></i>
                                        <i v-else class="pi pi-eye-slash text-gray-400 ms-2 !text-xs"></i>
                                    </div>
                                </div>
                                <div>
                                    {{ item.comment }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <!-- <div v-else class="flex flex-col justify-center h-full">
                <h2 class="text-center m-0">No comments</h2>
            </div> -->
        </ScrollPanel>

        
        <div class="flex flex-row gap-4 justify-items-center mt-2 pb-0 md:pb-6 px-2 md:px-0">
            <Textarea 
                class="basis-10/12 2xl:basis-11/12" 
                v-model="message" autoResize rows="1" 
                @keydown.enter.exact.stop.prevent="preventEnter" 
                @keyup.enter.exact.prevent.stop="sendMessage"
            />
            <div class="flex flex-col basis-2/12 2xl:basis-1/12 align-end justify-self-end">
                <Button class="!w-full" @click="sendMessage" icon="pi pi-send" />
            </div>
        </div>
    </div>
</template>

<script>
import { usePage } from '@inertiajs/vue3';
import axios from 'axios';
import moment from 'moment';
import { vElementVisibility } from '@vueuse/components';

export default {
    directives: {
        elementVisibility: vElementVisibility,
    },
    props: {
        modelValue: {
            type: Array,
            required: false,
            default: []
        },
        class: {
            type: String,
            required: false,
            default: null
        },
        realtime: {
            type: Boolean,
            required: false,
            default: false
        },
        lead: {
            type: Object,
            required: false,
            default: null
        }
    },
    setup(props) {
        const user = usePage().props.auth.user;
        return { user };
    },
    data() {
        return {
            message: null,
            sendingMessage: false
        };
    },
    methods: {
        preventEnter(event) {
        },
        sendMessage(event) {
            if (typeof this.message !== 'string' || this.sendingMessage)
                return;
            if (this.message.trim() && this.message.trim().length) {
                const comment = {
                    author_id: this.user.id,
                    created_at: moment().format('HH:mm:ss DD.MM.YYYY'),
                    comment: this.message
                };

                if (!this.realtime) {
                    let comments = this.comments;
                    comments.push(comment);
                    this.comments = comments;
                    this.message = null;
                    this.scrollToBottom();
                }
                else {
                    //TODO:убрать дребезжание кнопки
                    this.sendingMessage = true;
                    this.postComment(comment);
                }
            }
        },
        postComment(comment) {
            if (this.lead) {
                axios.post(route('lead.post_comment', { lead: this.lead }), {comment: comment}).then((response) => {
                    this.scrollToBottom();
                    this.message = null;
                    this.sendingMessage = false;
                });
            }
            else {
                console.error('No lead param specified');
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const refference = this.$refs.commentsPanel;
                if (refference) {
                    const commentsPanel = refference.$el.querySelector('.p-scrollpanel-content');
                    commentsPanel.scrollTop = commentsPanel.scrollHeight;
                }
            });
        },
        onCommentVisible(isVisible, comment) {
            if (isVisible && !comment?.read) {
                axios.post(route('lead.read_comment', { comment: comment })).then((response) => {
                    comment.read = response.data.read;
                });
            }
        }
    },
    computed: {
        comments: {
            get() {
                if (this.modelValue)
                    return this.modelValue;
                return [];
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    mounted() {
        this.scrollToBottom();
    },
    created() {
        if (this.realtime && this.lead) {
            Echo.private(`comments.lead.${this.lead.id}`)
                .listen('.CommentCreated', (e) => {
                    const model = e.model;
                    let comments = this.comments;
                    comments.push(model);
                    this.comments = comments;
                    this.scrollToBottom();
                });
        }
    },
    destroyed() {
        if (this.lead && this.realtime)
            Echo.leave(`comments.lead.${this.lead.id}`);
    }
}
</script>