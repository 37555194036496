<template>
    <Drawer v-model:visible="visible" :blockScroll="true" header="Уведомления" position="right" class="!w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
        <div class="card !p-2 flex flex-col flex-wrap">
            <div v-for="notification in notifications">
                <Notification
                    :notification="notification"
                    @read-notification="readNotification"
                />
            </div>
        </div>
        <template #footer>
            <div class="flex items-center gap-2">
                <Button @click.prevent="readAllNotifications()" label="Прочитать все" icon="pi pi-list-check" class="flex-auto" outlined></Button>
            </div>
        </template>
    </Drawer>
</template>

<script>
import { ref } from "vue";
import { usePermissions } from '../Composables/usePermissions';
import Notification from "../Components/Notification.vue";

export default {
    components: {
        Notification
    },
    props: {
        active: {
            type: Boolean,
            required: true
        },
        notifications: {
            type: Array,
            required: true
        },
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        return { hasRole, hasPermission };
    },
    data() {
        return {
        };
    },
    created() {
    },
    methods: {
        readNotification(id) {
            axios.patch(route('notifications.mark', id)).then((response) => {
                if (response.data) {
                    const index = this.notifications.findIndex((n) => n.id === id);
                    if (index > -1) {
                        this.notifications.splice(index, 1);
                        if (!this.notifications.length) {
                            this.visible = false;
                        }
                    }
                }
            });
        },
        readAllNotifications() {
            axios.patch(route('notifications.mark-all')).then((response) => {
                if (response.data) {
                    this.notifications.length = 0;
                }
            });
        }
    },
    computed: {
        visible: {
            get() {
                return this.active;
            },
            set(val) {
                this.$emit('update:active', val);
            }
        }
    },
    watch: {
        
    }
}
</script>