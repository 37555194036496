<template>
    <div :class="class">
        <FloatLabel>
            <DatePicker 
                v-model="date" 
                :inputId="id" 
                :name="name" 
                :readonly="readonly"
                showIcon 
                :fluid="fluid" 
                iconDisplay="input"
                :invalid="errors ? true : false"
                :dateFormat="dateFormat"
                :showButtonBar="showButtonBar"
                :inline="inline"
            />
            <label :for="id">{{ label }}</label>
        </FloatLabel>
        <small class="text-red-500">{{ errors }}</small>
    </div>
</template>

<script>
import moment from 'moment';
import { nanoid } from 'nanoid';

export default {
    props: {
        label: {
            type: String,
            required: false,
            default: null
        },
        modelValue: {
            type: [String, Date, null],
            required: true
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        class: {
            type: String,
            required: false,
            default: 'gap-2 w-full'
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        errors: {
            type: [Array, String],
            required: false,
            default: null
        },
        dateFormat: {
            type: String,
            required: false,
            default: 'dd.mm.yy'
        },
        showButtonBar: {
            type: Boolean,
            required: false,
            default: false
        },
        fluid: {
            type: Boolean,
            required: false,
            default: false
        },
        inline: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        date: {
            get: function () {
                if (typeof this.modelValue == 'string')
                    return moment(this.modelValue, 'DD.MM.YYYY').toDate();
                return this.modelValue;
            },
            set: function (value) {
                if (value) {
                    value = moment(value).format('DD.MM.YYYY');
                }
                this.$emit('update:modelValue', value);
                //this.$emit('input', newValue);
            }
        },
        id() {
            const id = nanoid(5);
            if (this.name)
                return this.name + "-" + id;
            return id;
        },
    },
    mounted() {},
}
</script>