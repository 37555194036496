<template>
    <div class="flex flex-row p-2 gap-4 items-center" @click.prevent.stop="readNotification">
        <Avatar :image="avatar" size="normal" shape="circle" class="w-1/4"></Avatar>
        <div class="flex flex-col w-5/6">
            <h6 class="text-sm font-weight-normal mb-1">
                Новая вакансия <span class="font-weight-bold">{{ text }}</span>
            </h6>
            <div class="flex flex-row items-center">
                <i class="pi pi-clock text-slate-500 me-2" style="font-size: 0.8rem"></i>
                <span class="text-xs text-slate-500">{{ time }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    methods: {
        readNotification() {
            this.$emit('readNotification', this.notification.id);
        }
    },
    computed: {
        avatar() {
            return this.notification.avatar;
        },
        text() {
            return this.notification.title;
        },
        time() {
            return this.notification.time;
        }
    }
}   
</script>